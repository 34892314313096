import React, { useState } from "react";
import "./ServicesAE.css";
import ourservices from "../../Images/ourservices.png";
import pancard from "../../Images/pancard.jpg";
import votercard from "../../Images/votercard.jpg";
import aadharcard from "../../Images/aadhar.png";
import govtJobs from "../../Images/government_jobs_pic.webp";
import janamKundali from "../../Images/janamKundali.png";
import electricityMeter from "../../Images/electricity_meter.jpg";
import waterMeter from "../../Images/water-meter.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ServicesAE = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  return (
    <>
      <section class="our-services-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6 our-services-left-col">
              <div class="expense-insurance-left">
                {/* <h5>Final Expense</h5> */}
                <h2>Our Services </h2>
                <p>
                  At our cyber cafe, we facilitate essential documentation
                  services including voter card registration, Aadhar card
                  issuance, PAN card application, as well as providing
                  assistance with Janam Kundli creation and other vital
                  documents.
                </p>
                <p>
                  Others services include-
                  <ul>
                    <li>Photocopying , Scanning & Printing</li>
                    <li>Passport Registration</li>
                    <li>
                      New Electricity & Water Connections and many more...
                    </li>
                  </ul>
                </p>
                {/* <a class="btn-explore btn be-btn m-0" href="/get-a-quote/">
                  Get a Quote
                </a> */}
              </div>
            </div>
            <div class="col-md-6 our-services-right-col">
              <LazyLoadImage
                src={ourservices}
                alt="services at our cafe"
                className={imageLoaded ? "loaded" : ""}
              />
            </div>
          </div>
        </div>
      </section>
      <section class="our-services-help-section">
        <div class="container">
          <div class="row our-services-help-row">
            <h2>Our Services include :</h2>
            <div class="col-md-4">
              <div class="our-services-help-wrapper">
                <div class="our-services-help-img">
                  <LazyLoadImage
                    src={aadharcard}
                    alt="aadhar-related-services"
                    onLoad={handleImageLoad}
                    className={imageLoaded ? "loaded" : ""}
                  />
                </div>
                <div class="our-services-help-content">
                  <h3>Aadhar Card</h3>
                  <p>
                    The Aadhaar program was constituted in 2016 when the Unique
                    Identification Authority of India (UIDAI) was set up. All
                    Aadhar cards are issued through the UIDAI, which collects
                    the cardholder's demographic and biometric data to enable a
                    more streamlined and transparent method of allocating
                    certain government benefits and subsidies to citizens. The
                    Aadhaar Card can be used as proof of identity and address.
                    In order to file Income Tax Returns, the Aadhaar and PAN
                    must be linked.
                  </p>
                  <p className="pt-3">
                    <a href="https://uidai.gov.in/">Click here to apply..</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="our-services-help-wrapper">
                <div class="our-services-help-img">
                  <LazyLoadImage
                    src={votercard}
                    alt="voter-card-related-services"
                    onLoad={handleImageLoad}
                    className={imageLoaded ? "loaded" : ""}
                  />
                </div>
                <div class="our-services-help-content">
                  <h3>Voter Card</h3>
                  <p>
                    Voter ID, also known as EPIC (Election Photo Identity Card)
                    is a photo identity card issued by the Election Commission
                    of India (ECI) to all Indian citizens eligible to vote. The
                    purpose of voter ID is to serve as identity proof for
                    voters, increase efficiency and prevent impersonation and
                    fraud during free and fair democratic elections. This card
                    is also commonly known as an election card, voter’s card, or
                    Voter ID card.
                  </p>
                  <p className="pt-3">
                    <a href="https://voters.eci.gov.in/">
                      Click here to apply..
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="our-services-help-wrapper">
                <div class="our-services-help-img">
                  <img
                    src={pancard}
                    alt="pan-card-related-services"
                    onLoad={handleImageLoad}
                    className={imageLoaded ? "loaded" : ""}
                  />
                </div>
                <div class="our-services-help-content">
                  <h3>PAN Card</h3>
                  <p>
                    A PAN card is issued under the Income Tax Act, 1961 and it
                    contains a unique 10-digit alphanumeric code. This code is
                    computer-generated and is unique to the holder of the card
                    and is valid for the lifetime. <br />
                    The eligibility for a PAN card is not limited to
                    individuals. Companies and partnership firms can also avail
                    a PAN Card and it becomes mandatory for such entities to
                    have a PAN when they are filing their income tax returns.
                    Even in case of individuals, minors, students and
                    Non-Resident Indians can also apply for PAN Card online.
                  </p>
                  <p className="pt-3">
                    <a href="https://www.pan.utiitsl.com/">
                      Click here to apply..
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="our-services-help-wrapper">
                <div class="our-services-help-img">
                  <img
                    src={govtJobs}
                    alt="pan-card-related-services"
                    onLoad={handleImageLoad}
                    className={imageLoaded ? "loaded" : ""}
                  />
                </div>
                <div class="our-services-help-content">
                  <h3>Government Jobs Form Filling</h3>
                  <p>
                    We provide services for filling Government Jobs Form and
                    that too with a nominal fees.
                  </p>
                  <p className="pt-3">
                    <a href="https://www.sarkariresult.com/">
                      Click here to view jobs..
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="our-services-help-wrapper">
                <div class="our-services-help-img">
                  <img
                    src={janamKundali}
                    alt="pan-card-related-services"
                    onLoad={handleImageLoad}
                    className={imageLoaded ? "loaded" : ""}
                  />
                </div>
                <div class="our-services-help-content">
                  <h3>Janam Kundli</h3>
                  <p>
                    Now , you can get your Janam Kundli right at your home with
                    the help of our courier service.
                  </p>
                  <p className="pt-3">
                    <a href="https://www.astro.com/horoscope">
                      Click here to apply..
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="our-services-help-wrapper">
                <div class="our-services-help-img">
                  <img
                    src={electricityMeter}
                    alt="pan-card-related-services"
                    onLoad={handleImageLoad}
                    className={imageLoaded ? "loaded" : ""}
                  />
                </div>
                <div class="our-services-help-content">
                  <h3>Apply for electricity meter</h3>
                  <p>
                    We are well expertised with solving such hectic tasks and
                    can help you with the installation of electricity meter
                    whether it's your home or commercial place.
                  </p>
                  <p className="pt-3">
                    <a href="https://www.bsesdelhi.com/web/bses">
                      Click here to apply..
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="our-services-help-wrapper">
                <div class="our-services-help-img">
                  <img
                    src={waterMeter}
                    alt="pan-card-related-services"
                    onLoad={handleImageLoad}
                    className={imageLoaded ? "loaded" : ""}
                  />
                </div>
                <div class="our-services-help-content">
                  <h3>Apply for Water meter</h3>
                  <p>
                    We are well expertised with solving such hectic tasks and
                    can help you with the installation of water meter
                    whether it's your home or commercial place.
                  </p>
                  <p className="pt-3">
                    <a href="https://delhijalboard.delhi.gov.in/">
                      Click here to apply..
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesAE;
