import React from "react";
import "./notFoundAE.css";
import { Link } from "react-router-dom";

const NotFoundAE = () => {
  return (
    <>
      <div className="notFoundContainer">
        <div>
          <h1 className="fourOfour">SORRY !</h1>
          <div className="notFoundText">
            <h2>This page is not available.</h2>
          </div>
        </div>
        <div className="goToHome">
          <Link to="/" className="text-decoration-none">Go to Home Page</Link>
        </div>
      </div>
    </>
  );
};

export default NotFoundAE;
