// import React, { useState } from "react";
// import "./HomebannerAE.css";
// import homebanner from "../../Images/home-banner-img.jpg";

// const HomebannerAE = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);

//   const handleImageLoad = () => {
//     setImageLoaded(true);
//   };
//   return (
//     <>
//       <section class="ae-home-section">
//         <div class="container">
//           <div class="row">
//             <div class="col-md-6 ae-left-col">
//               <div class="ae-hero-left-content">
//                 <h1>WELCOME TO AAKRITI ENTERPRISES</h1>
//                 <p>
//                   "Experience Connectivity at its Best: India's Premier Internet
//                   Cafe Destination!"
//                 </p>
//               </div>
//             </div>
//             <div class="col-md-6 ae-right-col">
//               <div class="ae-hero-right-content">
//                 <div class="ae-hero-right-img">
//                   <img
//                     src={homebanner}
//                     alt=""
//                     onLoad={handleImageLoad}
//                     className={imageLoaded ? "loaded" : ""}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default HomebannerAE;

import React, { useState } from "react";
import "./HomebannerAE.css";
import homebanner from "../../Images/home-banner-img.jpg";

const HomebannerAE = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  return (
    <>
      <section class="ae-home-section banner-image-container">
        <div class="container">
          <div class="row">
            <div class="col-12 ae-left-col">
              <div class="ae-hero-left-content">
                <h1>Welcome To Aakriti Enterprises</h1>
                <p>
                  "Experience Connectivity at its Best: India's Premier Internet
                  Cafe Destination!"
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomebannerAE;
