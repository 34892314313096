import React from "react";
import "./FooterAE.css";
import { Link } from "react-router-dom";

const FooterAE = () => {
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();

  return (
    <>
      <footer class="site-footer">
        <div class="footer-container">
          <div class="footer-top">
            <div class="row">
              <div class="col-md-4">
                <div class="footer-logo">
                  <Link to={"/"} className="footer-logo text-decoration-none">
                    {/* <img
                      //   src="https://goinsurewise.com/wp-content/themes/golnsurewise/images/footer-logo.png"
                      alt="footer-logo"
                    /> */}
                    <p>Aakriti Enterprises</p>
                  </Link>
                </div>
                <div class="footer-conatent">
                  <p>
                    Get your work done with Aakriti Enterprises: Your Wise
                    Choice for Internet services!
                  </p>
                  <h6>
                    <i class="bi bi-telephone"></i>
                    <a href="tel:9971501973" className="ps-2">
                      9971501973
                    </a>
                    <div className="d-flex align-items-center gap-2 pt-1">
                      <i class="bi bi-envelope-at"></i>
                      <p>ajay.delhi41@gmail.com</p>
                    </div>
                    <br />
                    <div className="d-flex gap-2 pt-3">
                      <i class="bi bi-geo-alt"></i>
                      <p>
                        C-134 , Kunwar Singh Nagar , Nangloi , New Delhi , India
                        - 110041
                      </p>
                    </div>
                  </h6>
                </div>
              </div>
              {/* <div class="col-md-2 col-sm-6 col-6">
                <div class="footer-menu">
                  <h3>Product</h3>
                  <div class="menu-footer-product-container">
                    <ul id="menu-footer-product" class="menu">
                      <li
                        id="menu-item-279"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-279"
                      >
                        <a href="https://goinsurewise.com/medicare/">
                          Medicare
                        </a>
                      </li>
                      <li
                        id="menu-item-277"
                        class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-225 current_page_item menu-item-277"
                      >
                        <a
                          href="https://goinsurewise.com/final-expense/"
                          aria-current="page"
                        >
                          Final Expense
                        </a>
                      </li>
                      <li
                        id="menu-item-278"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-278"
                      >
                        <a href="https://goinsurewise.com/life/">Life</a>
                      </li>
                      <li
                        id="menu-item-280"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-280"
                      >
                        <a href="https://goinsurewise.com/individual-family/">
                          Individual &amp; Family
                        </a>
                      </li>
                    </ul>
                  </div>{" "}
                </div>
              </div>
              <div class="col-md-2 col-sm-6 col-6">
                <div class="footer-menu">
                  <h3>Resources</h3>
                  <div class="menu-footer-resources-container">
                    <ul id="menu-footer-resources" class="menu">
                      <li
                        id="menu-item-282"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-282"
                      >
                        <a href="https://goinsurewise.com/blog/">Blog</a>
                      </li>
                      <li
                        id="menu-item-281"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-281"
                      >
                        <a href="https://goinsurewise.com/contact/">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>{" "}
                </div>
              </div> */}
              {/* <div class="col-md-4">
                <div class="footer-menu news-latter-wrap">
                  <h3>Stay up to date</h3>
                  <form>
                    <div class="news-latter d-flex align-items-center">
                      <input type="text" placeholder="Enter your email" />
                      <button class="btn">Subscribe</button>
                    </div>
                  </form>
                </div>
              </div> */}
            </div>
          </div>
          <div class="footer-bottom">
            <div class="d-flex align-items-center flex-wrap justify-content-between copy-res">
              <p class="copy-right">
                © {currentYear} Aakriti Enterprises. All rights reserved.
              </p>
              {/* <ul class="ter-pri-cook">
                <li>
                  <a href="/industry-partners/">Industry Partner</a>
                </li>
                <li>
                  <a href="/do-not-sell/">Do Not Sell</a>
                </li>
                <li>
                  <a href="/terms/">Terms</a>
                </li>
                <li>
                  <a href="/privacy/">Privacy</a>
                </li>
                <li>
                  <a href="/cookies/">Cookies</a>
                </li>
              </ul> */}
            </div>
            <div class="footer-notes">
              <ul>
                {/* <li>
                  Outside the Medicare Annual Enrollment Period, beneficiaries
                  may enroll in a Medicare Advantage plan only if they meet
                  certain criteria. A licensed insurance agent can help
                  determine if you are eligible.
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterAE;
