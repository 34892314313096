// import React, { useState, useEffect, useRef } from "react";
// import "./MainpageAE.css";
// import happyCustomers from "../../Images/happyCustomers.jpg";
// import allCardDocs from "../../Images/allCardDocs.jpg";
// import affordableprices from "../../Images/affordableprices.webp";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import FAQ from "../FAQ/FAQ";
// import HomebannerAE from "../HomebannerAE/HomebannerAE";
// import Testimonials from "../Testimonials/Testimonials";

// const MainpageAE = () => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [count, setCount] = useState(0);
//   const joinSectionRef = useRef(null);

//   useEffect(() => {
//     const handleIntersection = (entries) => {
//       if (entries[0].isIntersecting && count === 0) {
//         let start = 500;
//         const end = 1000;
//         const duration = 2000; // duration of the counting animation in ms
//         const incrementTime = duration / end;
//         const timer = setInterval(() => {
//           start += 1;
//           setCount(start);
//           if (start === end) {
//             clearInterval(timer);
//           }
//         }, incrementTime);
//       }
//     };

//     const observer = new IntersectionObserver(handleIntersection, {
//       threshold: 0.1,
//     });

//     if (joinSectionRef.current) {
//       observer.observe(joinSectionRef.current);
//     }

//     return () => {
//       if (joinSectionRef.current) {
//         observer.unobserve(joinSectionRef.current);
//       }
//     };
//   }, [count]);

//   const handleImageLoad = () => {
//     setImageLoaded(true);
//   };
//   return (
//     <>
//       <HomebannerAE />
//       <section class="better-features-section">
//         <div class="container">
//           <div class="better-features-hedding text-center">
//             <h3>Why Choose Us</h3>
//             <h6>
//               In Search of the Best Cyber Cafe? Here's Why You Should Choose Us
//             </h6>
//           </div>
//           <div class="better-wraper">
//             <div class="row">
//               <div class="col-md-4">
//                 <div class="better-card">
//                   <div class="better-img">
//                     <LazyLoadImage
//                       src={affordableprices}
//                       alt="affordable-prices"
//                       class="img-fluid why-choose-us-img"
//                       onLoad={handleImageLoad}
//                       className={imageLoaded ? "loaded" : ""}
//                     />
//                   </div>
//                   <div class="better-content">
//                     <h3>Affordable Pricing</h3>
//                     <p>
//                       Join us and discover how affordability meets excellence at
//                       our cyber cafe.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div class="col-md-4 mt-better">
//                 <div class="better-card">
//                   <div class="better-img">
//                     <LazyLoadImage
//                       src={allCardDocs}
//                       alt="all-services"
//                       class="img-fluid why-choose-us-img"
//                       onLoad={handleImageLoad}
//                       className={imageLoaded ? "loaded" : ""}
//                     />
//                   </div>
//                   <div class="better-content">
//                     <h3>Get all your work done at a single place</h3>
//                     <p>
//                       Experience streamlined efficiency for all your official
//                       document needs, from voter registration to PAN card
//                       application and beyond, all conveniently accessible from
//                       our internet cafe. Let us handle the paperwork while you
//                       relax in our comfortable space, ensuring a hassle-free
//                       process for all your administrative tasks.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div class="col-md-4 mt-better">
//                 <div class="better-card">
//                   <div class="better-img">
//                     <LazyLoadImage
//                       src={happyCustomers}
//                       alt="happy-customers"
//                       class="img-fluid why-choose-us-img"
//                       onLoad={handleImageLoad}
//                       className={imageLoaded ? "loaded" : ""}
//                     />
//                   </div>
//                   <div class="better-content">
//                     <h3>Customer Service at our best</h3>
//                     <p>
//                       Dedicated to exceeding customer expectations, our cyber
//                       cafe is committed to delivering unparalleled service with
//                       unwavering dedication. We strive tirelessly to ensure the
//                       utmost satisfaction of our patrons, embodying excellence
//                       in every interaction and endeavor.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <section
//         ref={joinSectionRef}
//         class="wrapper lets-join bg-auto no-overlay bg-image text-center bg-map"
//       >
//         <div class="container py-14 pt-md-16 pb-md-18">
//           <div class="row pt-md-12">
//             <div class="col-lg-10 col-xl-9 col-xxl-8 mx-auto">
//               <h3 class="display-4 px-lg-12 mb-3">
//                 Let's Join to make the difference
//               </h3>
//               <p class="lead text-center mb-6 px-md-16 px-lg-0 pb-3"></p>
//             </div>
//           </div>

//           <div class="row">
//             <div class="col-xl-10 mx-auto">
//               <div class="card image-wrapper bg-full bg-image bg-overlay counter-outside-container">
//                 <div class="card-body p-9 p-xl-10 counter-block">
//                   <div class="row align-items-center counter-wrapper gy-4 text-center text-white">
//                     <div class="col-12 col-lg-12 customer-counter-block">
//                       <p class="counter counter-lg text-white">{count}+</p>
//                       <p>Happy Customers</p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <Testimonials />
//       <FAQ />
//     </>
//   );
// };

// export default MainpageAE;

import React, { useState, useEffect, useRef } from "react";
import "./MainpageAE.css";
import happyCustomers from "../../Images/happyCustomers.jpg";
import allCardDocs from "../../Images/allCardDocs.jpg";
import affordableprices from "../../Images/affordableprices.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import FAQ from "../FAQ/FAQ";
import HomebannerAE from "../HomebannerAE/HomebannerAE";
import Testimonials from "../Testimonials/Testimonials";

const MainpageAE = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [count, setCount] = useState(0);
  const joinSectionRef = useRef(null);
  const cardRefs = useRef([]);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            cardRefs.current.forEach((card, index) => {
              setTimeout(() => {
                if (card) {
                  card.classList.add("animate", `delay-${index + 1}`);
                }
              }, index * 500); // delay each card by 500ms
            });
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleIntersection = (entries) => {
      if (entries[0].isIntersecting && count === 0) {
        let start = 500;
        const end = 1000;
        const duration = 2000; // duration of the counting animation in ms
        const incrementTime = duration / end;
        const timer = setInterval(() => {
          start += 1;
          setCount(start);
          if (start === end) {
            clearInterval(timer);
          }
        }, incrementTime);
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
    });

    if (joinSectionRef.current) {
      observer.observe(joinSectionRef.current);
    }

    return () => {
      if (joinSectionRef.current) {
        observer.unobserve(joinSectionRef.current);
      }
    };
  }, [count]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      <HomebannerAE />
      <section class="better-features-section">
        <div class="container">
          <div class="better-features-hedding text-center">
            <h3>Why Choose Us</h3>
            <h6>
              In Search of the Best Cyber Cafe? Here's Why You Should Choose Us
            </h6>
          </div>
          <div class="better-wraper" ref={sectionRef}>
            <div class="row">
              <div class="col-md-4">
                <div class="better-card" ref={(el) => (cardRefs.current[0] = el)}>
                  <div class="better-img">
                    <LazyLoadImage
                      src={affordableprices}
                      alt="affordable-prices"
                      class="img-fluid why-choose-us-img"
                      onLoad={handleImageLoad}
                      className={imageLoaded ? "loaded" : ""}
                    />
                  </div>
                  <div class="better-content">
                    <h3>Affordable Pricing</h3>
                    <p>
                      Join us and discover how affordability meets excellence at
                      our cyber cafe.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt-better">
                <div class="better-card" ref={(el) => (cardRefs.current[1] = el)}>
                  <div class="better-img">
                    <LazyLoadImage
                      src={allCardDocs}
                      alt="all-services"
                      class="img-fluid why-choose-us-img"
                      onLoad={handleImageLoad}
                      className={imageLoaded ? "loaded" : ""}
                    />
                  </div>
                  <div class="better-content">
                    <h3>Get all your work done at a single place</h3>
                    <p>
                      Experience streamlined efficiency for all your official
                      document needs, from voter registration to PAN card
                      application and beyond, all conveniently accessible from
                      our internet cafe. Let us handle the paperwork while you
                      relax in our comfortable space, ensuring a hassle-free
                      process for all your administrative tasks.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt-better">
                <div class="better-card" ref={(el) => (cardRefs.current[2] = el)}>
                  <div class="better-img">
                    <LazyLoadImage
                      src={happyCustomers}
                      alt="happy-customers"
                      class="img-fluid why-choose-us-img"
                      onLoad={handleImageLoad}
                      className={imageLoaded ? "loaded" : ""}
                    />
                  </div>
                  <div class="better-content">
                    <h3>Customer Service at our best</h3>
                    <p>
                      Dedicated to exceeding customer expectations, our cyber
                      cafe is committed to delivering unparalleled service with
                      unwavering dedication. We strive tirelessly to ensure the
                      utmost satisfaction of our patrons, embodying excellence
                      in every interaction and endeavor.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        ref={joinSectionRef}
        class="wrapper lets-join bg-auto no-overlay bg-image text-center bg-map"
      >
        <div class="container py-14 pt-md-16 pb-md-18">
          <div class="row pt-md-12">
            <div class="col-lg-10 col-xl-9 col-xxl-8 mx-auto">
              <h3 class="display-4 px-lg-12 mb-3">
                Let's Join to make the difference
              </h3>
              <p class="lead text-center mb-6 px-md-16 px-lg-0 pb-3"></p>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-10 mx-auto">
              <div class="card image-wrapper bg-full bg-image bg-overlay counter-outside-container">
                <div class="card-body p-9 p-xl-10 counter-block">
                  <div class="row align-items-center counter-wrapper gy-4 text-center text-white">
                    <div class="col-12 col-lg-12 customer-counter-block">
                      <p class="counter counter-lg text-white">{count}+</p>
                      <p>Happy Customers</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
      <FAQ />
    </>
  );
};

export default MainpageAE;
