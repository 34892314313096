import React from "react";
import "./AboutusAE.css";

const AboutusAE = () => {
  return (
    <>
      <section class="about-us-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6 about-us-left-col">
              <div class="about-us-left">
                <h2>About Us</h2>
                {/* <p>
                  At our cyber cafe, we facilitate essential documentation
                  services including voter card registration, Aadhar card
                  issuance, PAN card application, as well as providing
                  assistance with Janam Kundli creation and other vital
                  documents.
                </p>
                <p>
                  Others services include-
                  <ul>
                    <li>Photocopying , Scanning & Printing</li>
                    <li>Passport Registration</li>
                    <li>
                      New Electricity & Water Connections and many more...
                    </li>
                  </ul>
                </p> */}
                <p>
                  <span className="text-blue">Our Story :</span>
                  <br />
                  Our journey began with a simple idea: to bridge the digital
                  gap and make the online world accessible to everyone. With
                  India's rapid technological advancement, we recognized the
                  need for a place where people could not only access the
                  internet but also connect with others, pursue their passions,
                  and explore endless possibilities.
                </p>
                <p className="d-inline-flex">
                  <span className="text-blue pe-3">Founded In :</span>
                  <span>2019</span>
                </p><br />
                <p className="d-inline-flex">
                  <span className="text-blue pe-3">Founded By :</span>
                  <span>Ajay Mishra</span>
                </p>
              </div>
            </div>
            <div class="col-md-6 about-us-right-col">
              <img
                class="img-fluid"
                // src={ourservices}
                alt="services at our cafe"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutusAE;
