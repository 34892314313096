import * as React from "react";
import "./FAQ.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ() {
  return (
    <>
      <section class="faq-section">
        <div class="faq-container">
          <div class="faq-heding">
            <h3>Frequently Asked Questions</h3>
          </div>
          <div class="faq-wraper">
            <div class="accordion" id="accordionExample">
              <div>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="accordian-content"
                  >
                    <Typography>What are your operating hours?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Our cyber cafe is open from 9am to 10pm everyday. We're
                      here to serve you during our regular business hours.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                    className="accordian-content"
                  >
                    <Typography>
                      What documents are needed while filling up for Government
                      Jobs ?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Your Passport-sized Photograph , Aadhar Card and X , XII
                      marksheets are few important required documents.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
