import logo from "./logo.svg";
import "./App.css";
import NotFoundAE from "./components/notFoundAE/notFoundAE";
import HomeAE from "./components/HomeAE/HomeAE";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import FooterAE from "./components/FooterAE/FooterAE";
import MainpageAE from "./components/MainpageAE/MainpageAE";
import FAQ from "./components/FAQ/FAQ";
import Loader from "./components/Loader/Loader";
import ServicesAE from "./components/ServicesAE/ServicesAE";
import ContactAE from "./components/ContactAE/ContactAE";
import AboutusAE from "./components/AboutusAE/AboutusAE";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

function App() {
  return (
    <>
      <>
        <HomeAE />
        <Routes>
          <Route path="/" element={<MainpageAE />} />
          <Route path="*" element={<NotFoundAE />} />
          <Route path="/services" element={<ServicesAE />} />
          <Route path="/contact" element={<ContactAE />} />
          <Route path="/aboutus" element={<AboutusAE />} />
        </Routes>
        <FooterAE />
        <ScrollToTop/>
      </>

      {/* <ServicesAE/> */}
    </>
  );
}

export default App;
