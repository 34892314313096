import React from "react";
import "./ContactAE.css";

const ContactAE = () => {
  return (
    <>
      {/* <section className="contact-section position-relative overflow-hidden">
        <div className="container">
          <div className="contact-section-wrapper">
            <h2>Contact Us</h2>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Debitis
              quos a rem laborum soluta tempora assumenda voluptatem,
              perferendis in illo!
            </p>
          </div>
          <div className="contact-form-main position-relative">
            <div className="row">
              <div className="col-12 col-md-6 contact-form-col"></div>
              <div className="col-12 col-md-6 contact-form-col"></div>
            </div>
          </div>
        </div>
      </section> */}
      <section class="contact-section position-relative overflow-hidden">
        <div class="container">
          <div class="contact-section-wrapper">
            <h2>Contact Us</h2>
            <p class="font-size-20 dark-blue-color">
              We are here to help you with all the digital services , with our
              initiative , now no one should be left behind.
            </p>
          </div>
          <div class="contact-form-main position-relative">
            <div class="row">
              <div class="col-12 col-md-6 contact-form-col dark-blue-color">
                <p class="font-size-20">
                  You can call us anytime (24 X 7) or mail if you have any query
                  regarding any of our services.
                </p>
              </div>
              <div class="col-12 col-md-6 contact-form-col">
                <div class="d-flex gap-2 align-items-center">
                  <i class="bi bi-envelope-at font-size-20"></i>
                  <a href="mailto:ajay.delhi41@gmail.com">
                    ajay.delhi41@gmail.com
                  </a>
                </div>
                <div class="pt-4">
                  <i class="bi bi-telephone font-size-20"></i>
                  <a href="tel:9971501973" class="ps-2">
                    9971501973
                  </a>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactAE;
