import React from "react";
import "./Testimonials.css";

const Testimonials = () => {
  return (
    <>
      <section class="testimonials">
        {/* <div class="container">
          <div class="better-features-hedding text-center">
            <h3>Testimonials</h3>
          </div>
          <div class="better-wraper">
            <div class="row testimonial-row">
              <div class="card testimonial-card">
                <div class="card-body">
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <h5 class="card-title">Card title</h5>
                  <h6 class="card-subtitle mb-2 text-body-secondary">
                    Card subtitle
                  </h6>
                </div>
              </div>

              <div class="card testimonial-card">
                <div class="card-body">
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <h5 class="card-title">Card title</h5>
                  <h6 class="card-subtitle mb-2 text-body-secondary">
                    Card subtitle
                  </h6>
                </div>
              </div>

              <div class="card testimonial-card">
                <div class="card-body">
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <h5 class="card-title">Card title</h5>
                  <h6 class="card-subtitle mb-2 text-body-secondary">
                    Card subtitle
                  </h6>
                </div>
              </div>
              
            </div>
          </div>
        </div> */}
        
      </section>
    </>
  );
};

export default Testimonials;
